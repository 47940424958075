// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

@media screen and (max-width: 640px) {
  .topbar{
    display: inherit !important;
  }
  .topbar > div.logo-wrapper{
    flex: inherit !important;
        float: left !important;
        padding-right: 30px !important;
  }
  .topbar__menu{
  float:right !important;
      position: absolute !important;
    right: 10px !important;
  }

}

@media screen and (min-width: 641px) and (max-width: 900px){

  .topbar{
      display: inherit !important;
    }
  .topbar__search {
      position: relative;
      max-width: 190px;
      margin-top: 6px;
  }
  .topbar > div.logo-wrapper{
    width: 100%;
      text-align: center;
      justify-content: center;
      margin-bottom: 20px;
  }
  .show-for-medium{
     float: left;
  }
  .topbar__dropmenu{
        float: left;
  }
  .topbar__user{
    float: right;
      margin-top: 10px;
  }
}